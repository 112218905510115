import '../styles/Footer.css'

const Footer = () => {
    return (
        <footer id='footer'>
          © Callbck 2023 all rights reserved 
      </footer>
    )
}

export default Footer